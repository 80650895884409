// src/App.js
import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./components/ui/card";
import { Textarea } from "./components/ui/textarea";
import { Button } from "./components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/select";

const API_ENDPOINT = "https://api-f1db6c.stack.tryrelevance.com/latest/studios/3392e1d7-5af9-4a24-b033-d7ed2b49c29d/trigger_limited";
const API_KEY = "4c6ed513b111-4141-b6f3-dd4ca685b36e:sk-ZjhiYjMwZDItY2M2MC00YjAxLWFhNzMtYTg0NDBkNzNhNGJh";

const Header = () => (
  <header className="flex p-4 items-center">
    <img src="./logo512.png" alt="Logo" className="h-9 w-auto" />
    <header className="text-lg font-bold">LiteThink</header>
  </header>
);

const ContentTypeSelector = ({ setContentType }) => (
  <Select onValueChange={setContentType}>
    <SelectTrigger className="w-full">
      <SelectValue placeholder="Select content type" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="default">What do you want to revise?</SelectItem>
      <SelectItem value="interview">User Interview Transcript</SelectItem>
      <SelectItem value="problem">Problem Statement</SelectItem>
      <SelectItem value="other">Other Content</SelectItem>
    </SelectContent>
  </Select>
);

const ContentInput = ({ content, setContent, contentType }) => (
  <div>
    <label htmlFor="contentInput" className="block text-sm font-medium text-gray-700 mb-2 font-bold">Problem statement</label>
    <Textarea
      id="contentInput"
      placeholder={contentType === 'problem' ? 'Enter your Problem Statement here...' : 'Enter your Problem Statement here...'}
      value={content}
      onChange={(e) => setContent(e.target.value)}
      className="min-h-[200px]"
    />
  </div>
);

const GoalSelector = ({ setGoal }) => (
  <Select onValueChange={setGoal}>
    <SelectTrigger className="w-full">
      <SelectValue placeholder="Select goal" />
    </SelectTrigger>
    <SelectContent>
      <SelectItem value="Default">Why do you want to revise?</SelectItem>
      <SelectItem value="Research brief">Writing Research brief</SelectItem>
      <SelectItem value="Survey">Creating Quantitative Survey</SelectItem>
    </SelectContent>
  </Select>
);

const ContextInput = ({ context, setContext }) => (
  <div>
    <label htmlFor="contextInput" className="block text-sm font-medium text-gray-700 mb-2 font-bold">Additional Context</label>
    <Textarea
      id="contextInput"
      placeholder="Enter any additional context here..."
      value={context}
      onChange={(e) => setContext(e.target.value)}
      className="min-h-[200px]"
    />
  </div>
);

const SubmitButton = ({ onSubmit, isLoading, isDisabled }) => (
  <Button 
    onClick={onSubmit} 
    disabled={isLoading || isDisabled}
    className="bg-custom-blue text-white" // Add these classes
  >
    {isLoading ? "Take a deep breath..." : "Get Revised Problem Statements"}
  </Button>
);

const ResultDisplay = ({ result }) => {
  const resultArray = result.split('\n');
  const resultRef = useRef(null);

  useEffect(() => {
    if (resultRef.current) {
      resultRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [result]);

  return (
    <Card className="mt-4" ref={resultRef}>
      <CardHeader>
        <CardTitle>Revised Problem statements</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {resultArray.map((item, index) => (
            <li key={index} className="p-4 border rounded bg-blue-50">{item}</li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

const ProblemStatementAnalyzer = () => {
  const [contentType, setContentType] = useState("");
  const [context, setContext] = useState(""); // Add this line
  const [content, setContent] = useState("");
  const [goal, setGoal] = useState("");
  const [result, setResult] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsTooltipVisible(false);
      }
    };

    if (isTooltipVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTooltipVisible]);

  const handleSubmit = async () => {
    if (!content.trim()) {
      setResult("Please enter your problem statement.");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': API_KEY
        },
        body: JSON.stringify({
          params: {
            original_problem_statements: contentType === "problem" ? content : "",
            user_message: contentType !== "problem" ? content : "",
            user_goal: goal
          },
          project: "4c6ed513b111-4141-b6f3-dd4ca685b36e"
        }),
      });
      const data = await response.json();
      setResult(data.output.llm_response_answer || "No response from the AI.");
    } catch (error) {
      console.error('Error:', error);
      setResult("An error occurred while analyzing the content.");
    }
    setIsLoading(false);
  };

  return (
    <div className="container mx-auto">
      <Header />
    <div className="container mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle className="mb-4 flex items-center">
            Clear Problem Statements
            <div className="relative">
              <button 
                className="ml-2 bg-blue-500 text-white rounded px-2 py-1 text-sm"
                onClick={() => setIsTooltipVisible(!isTooltipVisible)}
              >
                7 Principles
              </button>
              <div 
                ref={tooltipRef}
                className={`absolute bg-white border p-2 rounded shadow text-sm tooltip ${isTooltipVisible ? '' : 'hidden'} w-96`}
                >
                Problem statements are revised to adhere to 7 Principles as below:
                <ol>
                  <li>Principle 1: Translate Solutions</li>
                  <li>Principle 2: Customer Perspective</li>
                  <li>Principle 3: Single Variable</li>
                  <li>Principle 4: Brevity</li>
                  <li>Principle 5: Eliminate Jargon</li>
                  <li>Principle 6: Segment Specific</li>
                  <li>Principle 7: Present Tense</li>
                </ol>
              </div>
            </div>
          </CardTitle>
          <ul className="list-disc ml-5 mt-4 text-sm text-gray-600 leading-relaxed">
            <li>If you're building a product or features for your users, this tool helps you analyze and revise problem statements.</li>
            <li>Enter your original problem statement and add additional context, then click the button to get revised problem statements.</li>
          </ul>        
        </CardHeader>
        <CardContent>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="sm:w-1/2 space-y-4">
              <ContentInput content={content} setContent={setContent} goal={contentType} />           
            </div>
            <div className="sm:w-1/2 space-y-4">
              <ContextInput context={context} setContext={setContext} />
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <SubmitButton onSubmit={handleSubmit} isLoading={isLoading} />
        </CardFooter>
      </Card>
      {result && <ResultDisplay result={result} />}
    </div>
  </div>  
  );
};

export default ProblemStatementAnalyzer;